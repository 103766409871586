import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PheonixDataGridProps from '../interfaces/PheonixDataGird';

export const PheonixDataGrid: React.FC<PheonixDataGridProps> = ({ rows, columns, paginationMode, components, style, pageSizeOptions, pageSizeChange, pageChange, currentPage, paginationMeta, ...props }) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const renderStatusCell = (row: { field?: string; value?: any; row?: any; }) => {
    return ` Success ${row.row.SuccessCount}  / Failed ${row.row.FailedCount} `;
  };

  const updatedColumns = columns.map(column => {
    if (column.field === "status") {
      return {
        ...column,
        valueGetter: renderStatusCell,
      };
    }
    return column;
  });

  return (
    <div style={{ width: '100%', display: "contents" }}>
      <DataGrid
        rows={rows}
        columns={updatedColumns}
        components={{ Toolbar: GridToolbar, ...components }}
        componentsProps={{
          toolbar: {
            csvOptions: {
              disableToolbarButton: false,
              fields: updatedColumns
                .filter((column) => column.field !== "actions")
                .map((column) => column.field),
              getRowId: (row:any) => row.id,
              valueGetter: (params: { field: string; value: any; }) => {
                if (params.field === 'status') {
                  return renderStatusCell(params);
                }
                return params.value;
              },
            },
            printOptions: { disableToolbarButton: true  },
            quickFilterProps: { debounceMs: 250 },
          },
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
        style={style}
        disableRowSelectionOnClick
        {...props} />
    </div>
  );
};
