import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  Box,
  Button,
  LinearProgress,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { PheonixCSVProps } from "../interfaces/PheonixCSVProps";
import CloseIcon from "@mui/icons-material/Close";
import { ERROR_MESSAGES, CSV, CONTACTS, UserStaticData } from "../Constant";
import PheonixSnackbar from "./PheonixSnackbar";
import axios from "axios";
import { api } from "../api";
import { ContactType } from "../interfaces/PheonixContact";
import Link from '@mui/material/Link';
import { saveAs } from 'file-saver';

const PheonixCSVModal: React.FC<PheonixCSVProps> = ({
  open,
  onClose,
  title,
  status,
  setStatus,
}) => {
  const [selectedFile, setSelectedFile] = useState<{ file: File | null }>({
    file: null,
  });
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [apiSuccess,setApiSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [Snackbaropen, setSnackbaropen] = useState(false);
  const [apiresponse, setApiresponse] = useState<Array<ContactType>>([]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    const Data = 3 * 1024 * 1024;
    if (file && file.size <= Data) {
      setSelectedFile({ file });
      setUploadSuccess(false);
      let uploadProgress = 0;
      const interval = setInterval(() => {
        uploadProgress += 10;
        setProgress(uploadProgress);
        if (uploadProgress >= 100) {
          clearInterval(interval);
          setUploadSuccess(true);
        }
      }, 500);

      const blob = new Blob([file]);
      const fileUrl = URL.createObjectURL(blob);
    } else if (file && file.size > Data) {
      setSnackbarMessage(CSV.FILE_SIZE);
      setSnackbaropen(true);
    } else {
      setSelectedFile({ file: null });
      setProgress(0);
    }
  };

  const handleImportCSV = async (uploadfile: any) => {
    const query = `
    mutation UploadContacts($file: Upload!) {
      uploadContacts(file: $file) {
        success
        message
      }
    }
    `;

    const formData = new FormData();
    formData.append("operations", JSON.stringify({ query, variables: { file: null } }));
    formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
    formData.append("0", uploadfile);
  
    try {
      const response = await axios.post(api.baseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.data.errors) {
        console.error( ERROR_MESSAGES.GRAPHQL_ERROR, response.data.errors);
      } else {

        const { success, message } = response.data.data.uploadContacts;
  
        if (success) {
          setApiSuccess(true);
          setStatus(true);
          setSnackbarMessage(CSV.FILE_SUCCESS_UPLOAD);
          setSnackbaropen(true);
          setTimeout(() => {
            onClose();
            fetchData();
          }, 3000);
          fetchData();
        } else {
          setSnackbarMessage(CSV.FILE_DATA_DIDNT_UPDATE);
          setSnackbaropen(true);
          setTimeout(() => {
            onClose(); 
          }, 3000);
        }
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.NETWORK_ERROR, error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
            query GetContact {
              getContact {
                id
                name
                phoneNumber
                address 
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseDataWithIndex = response.data.data.getContact.map(
        (item: {}, index: number) => ({
          ...item,
          sno: (index + 1).toString(),
        })
      );

      setApiresponse(responseDataWithIndex);
    } catch (error) {
      setSnackbarMessage(ERROR_MESSAGES.ERROR);
      setSnackbaropen(true);
    }
  };

  const handleFileremove = () => {
    setSelectedFile({ file: null });
    setUploadSuccess(false);
    setProgress(0);
    setSnackbarMessage(CSV.FILE_REMOVED);
    setSnackbaropen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbaropen(false);
  };

  useEffect(() => {
    if (uploadSuccess && selectedFile.file) {
      handleImportCSV(selectedFile.file);
    }
  }, [uploadSuccess, selectedFile]);

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    if (!open) {
      setSelectedFile({ file: null });
      setUploadSuccess(false);
      setProgress(0);
      setSnackbaropen(false); 
    }
  }, [open]);

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const handleDownloadCSV = () => {
    const staticData = UserStaticData;

  const formattedData = staticData.map(({ name, countrycode, mobile, address }) => ({
    name,
    countrycode,
    mobile:`${getFormattedMobile(mobile)}`,
    address
}));

const header = ["Name", "CountryCode","Contact Number", "Address"];
const data = [header, ...formattedData.map(({ name,  countrycode, mobile, address }) => [name,  countrycode, mobile, address])];

const csvContent = data.map(row => row.map(cell => typeof cell === 'string' ? `"${cell}"` : cell).join(',')).join('\n');

const blob = new Blob([csvContent], { type: 'text/csv' });
saveAs(blob, 'Csv Format.csv');
};

const getFormattedMobile = (mobile: any) => {
  let digitsOnly = mobile.replace(/\D/g, ''); 
  if (digitsOnly.length > 15) {
    digitsOnly = digitsOnly.substring(0, 15);
  }
  return digitsOnly;
};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
          width: "512px",
          height: "270px",
          padding: "4px 42px 4px 42px",
          boxShadow: "0px 4px 4px 0px #00000040",
          border: "1px solid #0000001F",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "24px",
          fontWeight: "700",
          fontFamily: "Inter",
          lineHeight: "25.05px",
          color: "#1C1B1F",
          marginLeft: "-7%",
          marginTop:"2%",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 15,
          color: "#000000",
          marginTop:"2%",
        }}
      >
        <CloseIcon sx={{ color: "black" }} />
      </IconButton>
      <DialogContent>
        <Box
          sx={{
            width: "340px",
            height: "122.65px",
            marginLeft: "15%",
            borderRadius: "30px",
            borderWidth: 2,
            borderStyle: uploadSuccess ? "" : "dashed",
            borderColor: "#0000001F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {!selectedFile.file ? (
            <>
              <UploadFileIcon
                sx={{
                  color: "#2196F380",
                  marginBottom: "8px",
                }}
              />

              <Button
                component="label"
                variant="text"
                sx={{
                  color: "#2196F380",
                  textDecoration: "underline",
                  marginBottom: "10px",
                }}
              >
                <label
                  htmlFor="file-upload"
                  style={{
                    textDecoration: "underline",
                    color: "#2196F380",
                  }}
                >
                  <input
                    id="file-upload"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  {CONTACTS.UPLOAD_CSV_FILE}
                </label>
              </Button>

              <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "roboto",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#00000099",
                }}
              >
                {CONTACTS.UPLOAD_IN_CSV}
              </Typography>
            </>
          ) : (
            <>
              {uploadSuccess ? (
                <>
                  <UploadFileIcon sx={{ color: "#2196F380" }} />
                  <Typography sx={{ fontSize: "14px" }}>
                    {selectedFile.file.name}
                  </Typography>
                  <Typography sx={{ fontSize: "13px" }}>
                    {CONTACTS.FILE_UPLOADED_SUCCESSFULLY}
                  </Typography>
                </>
              ) : (
                <>
                  <UploadFileIcon sx={{ color: "#2196F380" }} />
                  <Typography sx={{ fontSize: "14px" }}>
                    {selectedFile.file.name}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ width: "60%", marginTop: "5px" }}
                  />
                </>
              )}
              
            </>
          )}
        </Box>
        <PheonixSnackbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
            fontSize: "24px",
          }}
          open={Snackbaropen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          vertical="top"
          horizontal="center"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          sx={{
            width: "320px",
            height: "42px",
            "& .MuiSnackbarContent-root": {
              background: " #323232",
            },
          }}
        />
      </DialogContent>
      <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "roboto",
                  fontSize: "14px",
                  marginLeft:"10%",
                  marginTop:"-1px",
                  lineHeight: "20px",
                  color: "#00000099",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Upload contacts in the recommended template format.
                <Link href="#" onClick={(e) => {preventDefault(e); handleDownloadCSV();}} sx={{ color: '#2196F3' }}>
                  Get template</Link>
              </Typography>
    </Dialog>
  );
};

export default PheonixCSVModal;
