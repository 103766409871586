import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import App from "./App";
import { api } from "./api";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { SessionContextProvider, useSession } from "./contexts/SessionContext";

const client = new ApolloClient({
  uri: api.baseUrl,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <SessionContextProvider>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </SessionContextProvider>,
  document.getElementById("root")
);
