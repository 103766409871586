import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PheonixDataGridProps from '../interfaces/PheonixDataGird';

export const PheonixDataGridSearch: React.FC<PheonixDataGridProps> = ({ rows, columns,paginationMode, components, style, pageSizeOptions, pageSizeChange, pageChange, currentPage,paginationMeta, ...props }) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5, 
    page: 0,   
  });

  return (
    <div style={{ width: '100%', display: "contents" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
        style={style}
        disableRowSelectionOnClick
        {...props} />
    </div>
  );
};
