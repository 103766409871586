import React, { useState } from "react";
import Button from "@mui/material/Button";
import { PheonixButtonProps } from "../interfaces/PheonixButton";

const PheonixButton: React.FC<PheonixButtonProps> = ({
  variant = "contained",
  color = "primary",
  disabled = false,
  size = "large",
  fullWidth = false,
  onClick,
  children,
  marginTop,
  marginLeft,
  startIcon,
  sx,
}) => {

  const handleClick = () => {
    onClick?.();
  };

  return (
    <Button
      variant={variant}
      color={color}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      startIcon={startIcon}
      onClick={handleClick}
      sx={{
        fontWeight: "bold",
        width: "auto",
        "&:hover": {
          variant: "contained",
        },
        "&:active": {
          variant: "contained",
        },
        marginTop: marginTop,
        marginLeft: marginLeft,

        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export default PheonixButton;
