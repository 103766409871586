import React, { ReactNode } from "react";
interface AppBarProps {
  title: string;
  children?: ReactNode;
  isSidebarOpen: boolean;
}

const AppBar: React.FC<AppBarProps> = ({ title, children, isSidebarOpen }) => {
  const titleMarginLeft = isSidebarOpen ? "260px" : "100px";

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "48px",
        padding: "10px",
        borderBottom: "1px solid #ccc",
        width: "100%",
        display: "flex",
        alignItems: "center",
        position: "relative",
        zIndex: 1,
        boxShadow: "0px 4px 4px 0px #00000040",
      }}
    >
      <h2
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          marginLeft: titleMarginLeft,
          flex: "1",
          fontFamily:'inter',
          color:'#1C1B1F'
        }}
      >
        {title}
      </h2>
      {children}
    </div>
  );
};

export default AppBar;
