import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "../index.css";
import Header from "../components/Header";

const Summary: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box style={{ display: "flex" }}>
      <Box
        style={{
          marginLeft: isSidebarOpen ? 240 : 0,
          transition: "margin-left 0.3s",
          width: "100%",
        }}
      >
        <Box sx={{ marginTop: "-40px", marginLeft: "20px" }}>REPORT</Box>
      </Box>
    </Box>
  );
};

export default Summary;
