import React from "react";
import { Snackbar as MuiSnackbar, SnackbarProps } from "@mui/material";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbar";

const PheonixSnackbar: React.FC<PheonixSnackbarProps> = ({
  vertical = "top",
  horizontal = "center",
  ...props
}) => {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      color="red"
      {...props}
    />
  );
};

export default PheonixSnackbar;
