import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const MessageSkeletonLoader = () => {
  return (
    <Box width="95%" marginTop={3} marginLeft={5}>
      {[...Array(6)].map((_, index) => (
        <Box key={index} marginBottom={2}>
          <Skeleton variant="rectangular" height={50} />
        </Box>
      ))}
    </Box>
  );
};

export default MessageSkeletonLoader;