import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixButton from "./PheonixButton";
import { ERROR_MESSAGES, close } from "../Constant";
import PheonixDeleteModalProps from "../interfaces/PheonixDeleteModal";

const PheonixDeleteModal: React.FC<PheonixDeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  entity,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
          width: "500px",
          padding: "3px 30px",
          boxShadow: "0px 4px 4px 0px #00000040",
          border: "1px solid #0000001F",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
      }}
    >
      <DialogTitle
        style={{
          marginLeft: "-13px",
          fontSize: "24px",
          fontFamily: "Inter",
          marginTop: "20px",
          fontWeight: "700",
        }}
      >
        {ERROR_MESSAGES.DELETE_CONFIRMATION}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: "67px",
            top: "45px",
            width: " 24px",
            height: " 24px",
            padding: "5px",
            color: "#000000",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: "inline-flex",
          fontSize: "16px",
          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
          letterSpacing: "0.15px",
          fontWeight: 400,
          paddingTop: "4px",
          paddingBottom: "30px",
          marginLeft: "-5px",
          fontFamily: "Inter",
        }}
      >
        {ERROR_MESSAGES.DELETE_MSG} {entity}?
      </DialogContent>
      <DialogActions>
      <Button
            onClick={onClose}
            sx={{
              fontWeight: "bold",
              borderRadius:"4px",
              padding:"8px 22px",
              width: "84px",
              height: "36px",
              backgroundColor: "#D32F2F",
              margin:"0px 15px 32px 0px",
              color: "white",
              "&:hover": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
              "&:focus": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
            }}
          >
          {close.CANCEL}
          </Button>
        <PheonixButton
          onClick={onConfirm}
          sx={{
            color: "white",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            fontFamily: "Inter",
            marginBottom: "30px",
            marginRight: "15px",
            width: "84px",
            height: "36px",
          }}
        >
          {ERROR_MESSAGES.DELETE}
        </PheonixButton>
      </DialogActions>
    </Dialog>
  );
};

export default PheonixDeleteModal;
