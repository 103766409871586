import React from "react";
import { Card, CardContent, Box, Typography, Link } from "@mui/material";
import PheonixPaper from "./PheonixPaper";
import { PheonixCardProps } from "../interfaces/PheonixCard";
import { display } from "@mui/system";

const PheonixCard: React.FC<PheonixCardProps> = ({ name, count, icon }) => {
  return (
    <Card
      style={{
        display: "flex",
        width: "270px",
        height: "120px",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow:
          "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
        margin: "auto",

        marginLeft:'3%'
      }}
    >
      <CardContent style={{ display: "flex", flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontFamily: "roboto",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24.7px",
                letterSpacing: "0.25px",
                color: "#000000DE",
                marginTop: "5%",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "roboto",
                fontSize: "30px",
                fontWeight: "700",
                lineHeight: "37.05px",
                letterSpacing: "0.25px",
                color: "#000000DE",
                marginTop: "15%",
              }}
            >
              {count}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginRight: "10%" }}
          >
            <PheonixPaper
              elevation={4}
              style={{
                width: "55px",
                height: "50px",
                borderRadius: "10px",
                backgroundColor: "#59356C0A",
                boxShadow:
                  "0px 5px 3px -3px #00000022, 0px 8px 5px 1px #00000014, 0px 3px 7px 2px #00000010",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50px",
                  height: "50px",
                }}
              >
                {icon}
              </Box>
            </PheonixPaper>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PheonixCard;
