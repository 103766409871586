import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PheonixSideBarProps from "../interfaces/PheonixSideBarProps";

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({ icon, text, to }) => {
  const location = useLocation();

  return (
    <ListItem
      sx={{
        textDecoration: "none",
        backgroundColor: location.pathname === to ? "#FFFFFF40" : "transparent",
        color: "inherit",
        "&:hover": { backgroundColor: "#FFFFFF40", textDecoration: "none" },
        fontSize: "20px",
        width: "217px",
        "& .MuiTypography-root": {
          fontSize: "18px",
          fontWeight: "500",
        },
      }}
      component={Link}
      to={to}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default PheonixSideBar;
