import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixModalProps from "../interfaces/PheonixModalProps";
import PheonixButton from "./PheonixButton";
import { ERROR_MESSAGES, REGEX, CONTACTS, COUNTRY_CODE } from "../Constant";

const PheonixEditModal = <T,>({
  open,
  onClose,
  title,
  fields,
  close,
  onConfirm,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  errorMessage,
  nameerror,
  phonenumbererror,
  setErrorMessage,
  setNameerror,
  setPhonenoerror,
  disabled,
  ...rest
}: PheonixModalProps<T>) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  const validateForm = () => {
    let valid = true;
    const newErrors: Record<string, string> = {};

    fields.forEach((field) => {
      const fieldValue = (formData as Record<string, string>)[field.label];
      if (field.label === "Name") {
        if (!REGEX.NAME_REGEX.test(fieldValue.trim())) {
          newErrors[field.label] = ERROR_MESSAGES.CONTACTS_NAME;
          valid = false;
        }
      } else if (field.label === "Contact Number") {
        if (
          fieldValue.length !== 10 ||
          !REGEX.CONTACTS_REGEX.test(fieldValue.trim())
        ) {

          newErrors[field.label] = ERROR_MESSAGES.CONTACTS_NUMBER;
          valid = false;
        }
      }
    });

    if (!valid) {
      setErrors(newErrors);
    }

    return valid;
  };

  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as Record<string, string>)[fieldLabel];
    const newErrors: Record<string, string> = { ...errors };

    if (fieldLabel === "Name") {
      if (!REGEX.NAME_REGEX.test(fieldValue.trim())) {
        newErrors[fieldLabel] = ERROR_MESSAGES.CONTACTS_NAME;
      } else {
        delete newErrors[fieldLabel];
      }
    } else if (fieldLabel === "Contact Number") {
      if (!REGEX.CONTACTS_REGEX.test(fieldValue.trim())) {
        newErrors[fieldLabel] = ERROR_MESSAGES.NUMBER_FIELD;
      } else if (fieldValue.trim().length !== 10) {
        newErrors[fieldLabel] = ERROR_MESSAGES.CONTACTS_NUMBER;
      } else {
        delete newErrors[fieldLabel];
      }
    }

    setErrors(newErrors);
  };

  const FormData = formData as Record<string, string>;

  const isSaveDisabled = () => {
    const nameValid = REGEX.NAME_REGEX.test(FormData["Name"]?.trim());
    const phoneValid =
      REGEX.CONTACTS_REGEX.test(FormData["Contact Number"]?.trim()) &&
      FormData["Contact Number"]?.trim().length === 10;

    return !(nameValid && phoneValid);
  };


  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
            "& .MuiDialog-paper": { 
              backgroundColor: "white",
              padding: "20px 20px 20px 20px",
              boxShadow: "0px 4px 4px 0px #00000040",
              border: "1px solid #0000001F",
              minHeight: "100px",
             },
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
      >
        <DialogTitle
          sx={{
            fontFamily: "Inter",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "29.05px",
            color: "#59356C",
          }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: "40px",
              top: 20,
              color: "inherit",
            }}
          >
            <CloseIcon sx={{ color: "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginTop: "16px" }}>
            <Grid container spacing={3}>
              {fields.map((field) => (
                <Grid item xs={12} key={field.label}>
                  {field.type === "textarea" ? (
                    <TextareaAutosize
                      aria-label={field.label}
                      placeholder={field.label}
                      minRows={3}
                      style={{ width: "100%" }}
                      value={FormData[field.label]}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      fullWidth
                      label={field.label}
                      variant="outlined"
                      value={FormData[field.label]}
                      onChange={(e) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                      onBlur={() => validateField(field.label)}
                      error={!!errors[field.label]}
                      helperText={errors[field.label]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {COUNTRY_CODE}
                          </InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      minRows={field.label === "Address" ? 2 : 1}
                      label={field.label}
                      variant="outlined"
                      value={FormData[field.label]}
                      onChange={(e) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                      onBlur={() => validateField(field.label)}
                      error={!!errors[field.label]}
                      helperText={errors[field.label]}
                      disabled={field.disabled}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
         onClick={onClose}
         sx={{
         fontWeight: "bold",
         width: "15%",
         height: "42px",
         marginBottom: "25px",
         backgroundColor: "#D32F2F",
         color: "white",
         "&:hover": {
          backgroundColor: "#D32F2F",
          color: "white", 
        },
        "&:focus": {
          backgroundColor: "#D32F2F",
          color: "white", 
        },
      }}
    >
      Cancel
    </Button>
          <PheonixButton
            onClick={() => validateForm() && onConfirm()}
            color="primary"
            sx={{ marginRight: "16px", marginBottom: "25px" }}
            disabled={isSaveDisabled()}
          >
            {confirmButtonText}
          </PheonixButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PheonixEditModal;
