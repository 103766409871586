import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ERROR_MESSAGES, MESSAGES, Text } from "../Constant";
import "../index.css";
import PheonixCard from "../components/PheonixCard";
import { Typography, Stack, Tooltip, Chip } from "@mui/material";
import {
  ContactPhoneOutlined,
  MessageOutlined,
  GroupsOutlined,
} from "@mui/icons-material";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import PheonixTable from "../components/PheonixTable";
import PheonixFooter from "../components/PheonixFooter";
import axios from "axios";
import { api } from "../api";
import moment  from 'moment';
import HomeSkeletonLoader from "../components/PheonixLoader";
import { PheonixDataGrid } from "../components/PheonixDataGrid";

interface ApiResponse {
  contactsCount: number;
  groupsCount: number;
  messagesSent: number;
  messages: {
    message: string;
    date: string;
    recipients: string[]; 
  }[];
  smsCounts: {
    sent: number;
  };
}

interface GetFailedResponse {
  failed: number;
}

const Home: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [apiresponse, setApiresponse] = useState<ApiResponse | null>(null);
  const [getfailedresponse, setgetfailedresponse] = useState<GetFailedResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [graphdata, setGraphdata] = useState<{
    countsArray: number[];
    totalMessages: number;
  } | null>(null);

  const formatDateString = (dateString: string) => {
    return moment(dateString, "MM/DD/YYYY hh:mm A").format("DD/MM/YYYY hh:mm A");
  };

  const fetchData = async () => {
    const query = `
    mutation GetTotalCounts {
      getTotalCounts {
        contactsCount
        groupsCount
        messagesSent 
        messages {
          message
          date
          recipients         
        }
        smsCounts {
          sent
        }
      }
    }
  `;
    try {
      const response = await axios.post(api.baseUrl, {
        query,
      });
      const { success, message } = response.data.data.getTotalCounts;
      setApiresponse(response.data.data.getTotalCounts);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFailedData = async () => {
    const query =`
    query GetFailedCount {
      getFailedCount {
          failed
      }
  }
  `;
  try {
    const response = await axios.post(api.baseUrl, {
      query,
    });
    const { success, message } = response.data.data.getFailedCount;
    setgetfailedresponse(response.data.data.getFailedCount);
  } catch (error) {
    console.error(error);
  }
};
  useEffect(() => {
    fetchData();
    fetchGraphData();
    fetchFailedData();
  }, []);

  const fetchGraphData = async () => {
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
          query GetMessagesByMonthwithDate{
            getMessagesByMonthwithDate{
              countsArray
              totalMessages
            }
          }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setGraphdata(response.data.data.getMessagesByMonthwithDate);
    } catch (error) {
      console.error(error);
    }
  };

  const data =
    apiresponse?.messages.map((item, index) => ({
      ...item,
      id: index.toString(),
      sno: apiresponse.messages.length - index,
      recipients: item.recipients.join(', '),
      date: formatDateString(item.date),
    })) || [];

  const reversedData = data.slice().reverse();

  const data1 = [
    {
      name: "Contacts",
      count: apiresponse?.contactsCount || 0,
      icon: (
        <ContactPhoneOutlined
          sx={{ color: "#59356C", width: "40px", height: "40px", marginLeft:"10%" }}
        />
      ),
    },
    {
      name: "Groups",
      count: apiresponse?.groupsCount || 0,
      icon: (
        <GroupsOutlined
          sx={{ color: "#E00F41", width: "40px", height: "40px", marginLeft:"10%" }}
        />
      ),
    },
    {
      name: "SMS Sent",
      count: apiresponse?.smsCounts.sent || 0,
      icon: (
        <MessageOutlined
          sx={{ color: "#2E7D32", width: "40px", height: "40px", marginLeft:"15%" }}
        />
      ),
    },
    {
      name: "SMS Failed",
      count: getfailedresponse?.failed || 0,
      icon: (
        <MessageOutlined
          sx={{ color: "#A90404", width: "40px", height: "40px", marginLeft:"15%" }}
        />
      ),
    },
  ];

  const column = [
    { field: "sno", headerName: "S.No" },
    { field: "date", headerName: "Date", width: "200" },
    { field: "recipients", headerName: "Recipient",width: "300"},
    { field: "message", headerName: "Message", width: "300"},
  ];

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#F1F1F1",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1.5%",
          justifyContent: "space-between",
          alignItems:"normal",
          marginRight:"1%",
        }}
      >
        {data1.map((item) => (
          <PheonixCard
            key={item.name}
            name={item.name}
            count={item.count}
            icon={item.icon}
          />
        ))}
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "5px" }}>
        <Box
          sx={{
            width: "66%",
            height: "455px",
            marginTop: "2%",
            marginLeft: "3%",
            borderBottomLeftRadius: "20px",
            borderRadius: "10px",
            border: "1px solid #0000001F",
            boxShadow: "0 2px 4px rgba(0,0,0,0.7)",
            backgroundColor: "white",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "roboto",
              fontSize: "20px",
              lineHeight: "20px",
              letterSpacing: "0.25px",
              marginTop: "2%",
              marginLeft: "2%",
              marginBottom: "1%",
            }}
          >
            {Text.RECENT}
          </Typography>
          {loading ?
          <Chip
          label={MESSAGES.MESSAGE_DATA}
          variant="outlined"
          sx={{width:"40%", marginTop: "1%", marginLeft: "30%" }}
          />
          : null
          }

        {loading ? 
          <HomeSkeletonLoader />
        : (
            <PheonixDataGrid
              columns={column}
              rows={reversedData.length > 0 ? reversedData : reversedData}
              style={{ border: '0px', marginLeft: '20px'}}
            />
        )}   
        </Box>
        <Box
          sx={{
            width: "26%",
            height: "455px",
            backgroundColor: "white",
            padding: "4px,42px,4px,42px",
            marginLeft: "2%",
            marginTop: "2%",
            border: "1px solid #0000001F",
            boxShadow: "0 2px 4px rgba(0,0,0,0.7)",
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "roboto",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "20px",
              letterSpacing: "0.25",
              color: "black",
              marginTop: "5%",
              marginLeft: "3%",
            }}
          >
            {ERROR_MESSAGES.TOTAL_MESSAGE_SENT}
          </Typography>
          <Box
            sx={{
              width: "79.5%",
              height: "330px",
              padding: "12px 20px 16px 16px",
              gap: "12px",
              borderRadius: "4px",
              marginLeft: "5%",
              marginTop: "3%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "roboto",
                fontWeight: "400",
                fontSize: "34px",
                lineHeight: "36px",
                marginTop: "5%",
                marginLeft: "45%",
              }}
            >
              {graphdata?.totalMessages}
            </Typography>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Box sx={{ flexGrow: 1, marginTop: "5%" }}>
                <SparkLineChart
                  data={graphdata?.countsArray || []}
                  height={230}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
        <PheonixFooter />
      </Box>
    </Box>
  );
};

export default Home;
