import React, { HTMLAttributes, MutableRefObject } from 'react';
import { VariableSizeList } from 'react-window';

interface VirtualListboxProps extends HTMLAttributes<HTMLDivElement> {
    forwardedRef?: MutableRefObject<HTMLDivElement> | null;
  }  

    const VirtualListbox: React.FC<VirtualListboxProps> = React.forwardRef((props, ref) => {
    const { forwardedRef, children, ...other } = props;
    const itemCount = React.Children.count(children);
    const itemData = React.Children.toArray(children);
  
    const getItemSize = (index: number) => 48;

    return (
        <div ref={forwardedRef} {...other}>
        <VariableSizeList
          itemData={itemData}
          height={300} 
          itemCount={itemCount}
          itemSize={getItemSize}
          width="100%"
        >
          {renderRow}
        </VariableSizeList>
      </div>
    );
  }
);

function renderRow(props: { data: any; index: number; style: React.CSSProperties }) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: { ...style, display: 'flex', alignItems: 'center' },
  });
}

export default VirtualListbox;
