import React, { useEffect } from "react";
import { useSession } from "../contexts/SessionContext";
import { useNavigate } from "react-router-dom";

const Logoff: React.FC = () => {
  const { isLoggedIn, logout } = useSession();
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate("/", { replace: true });
  }, []);
  return <div></div>;
};

export default Logoff;
