import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  Button,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import PheonixGroupModalProps from "../interfaces/PheonixGroupModel";
import PheonixButton from "./PheonixButton";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Contact } from "../interfaces/PheonixContact";
import { api } from "../api";
import CloseIcon from "@mui/icons-material/Close";
import { CONTACTS } from "../Constant";
import VirtualListbox from "./PheonixVirtual";
const PheonixGroupModal = <T,>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  contacts,
}: PheonixGroupModalProps<T>) => {
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [allContacts, setAllContacts] = useState<Contact[]>([]);
  const [availableContacts, setAvailableContacts] = useState<Contact[]>([]);
  const [isSavedEnabled, setisSaveEnabled] = useState(false);
  const [groupNameError, setGroupNameError] = useState<string>("");

  const handleContactSelection = (selectedContacts: Contact[]) => {
    setSelectedContacts(selectedContacts);
    const contactIds = selectedContacts.map((contact) => contact.id);
    setFormData((prevData) => ({
      ...prevData,
      contactIds: contactIds.join(","),
    }));
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    if (!open) {
      setSelectedContacts([]);
      setFormData({} as T);
    }
  }, [open]);

  const typedFormData = formData as Record<string, string>;

  useEffect(() => {
    const isGroupNameValid = isValidGroupName(typedFormData["Name"]);
    const isAnyTextFieldEmpty = fields.some((field) => {
      const fieldValue = typedFormData[field.label];
      if (field.label === "Add Contacts") {
        return selectedContacts.length === 0;
      }
      return (
        !fieldValue ||
        (Array.isArray(fieldValue) && fieldValue.length === 0) ||
        typeof fieldValue !== "string"
      );
    });

    setisSaveEnabled( isGroupNameValid && !isAnyTextFieldEmpty);
  }, [fields, typedFormData, selectedContacts]);

  const fetchContacts = async () => {
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
            query GetContact {
              getContact {
                id
                name
                phoneNumber
                address 
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const contactsData = response.data.data.getContact;
      const filteredContacts = contactsData.filter(
        (contact: Contact) =>
          !selectedContacts.some(
            (selectedContact) => selectedContact.id === contact.id
          )
      );

      setAllContacts(filteredContacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const filteredContacts1 = allContacts.filter(
    (contact) =>
      !contacts?.some((existingContact) => existingContact.id === contact.id)
  );
  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
  };
  const NAME_REGEX = /^[a-zA-Z0-9_]*$/;

  const isValidGroupName = (value: string) => {
    return NAME_REGEX.test(value);
  };

  const handleGroupNameChange = (value: string) => {
    const isValid = isValidGroupName(value);
  setGroupNameError(isValid ? "" : CONTACTS.GROUP_NAME_VALIDATION_ERROR);
  handleFieldChange("Name", value);
};

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "white",
            width: "70%",
            height: "400px",
            padding: "3px 30px",
            boxShadow: "0px 4px 4px 0px #00000040",
            border: "1px solid #0000001F",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            height: "80px",
            display: "flex",
            alignItems: "center",
            padding: "0px",
            marginLeft: "25px",
            marginTop: "10px",
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 60,
            top: 40,
            color: "#000000",
          }}
        >
          <CloseIcon sx={{ color: "#000000" }} />
        </IconButton>
        <DialogContent>
          <DialogContentText style={{ marginTop: "16px" }}>
            <Grid container spacing={3}>
              {fields.map((field) => (
                <Grid item xs={12} key={field.label}>
                  {field.label === "Group Name" && field.disabled ? (
                    <TextField
                      fullWidth
                      label={field.label}
                      variant="outlined"
                      value={typedFormData[field.label]}
                      InputProps={{ readOnly: true }}
                      style={{ width: "100%" }}
                    />
                  ) : field.type === "textarea" ? (
                    <TextareaAutosize
                      aria-label={field.label}
                      placeholder={field.label}
                      minRows={5}
                      style={{ width: "100%" }}
                      value={typedFormData[field.label]}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                    />
                  ) : field.type === "autocomplete" ? (
                    <Autocomplete
                      multiple
                      id={field.label.toLowerCase().replace(/\s/g, "-")}
                      options={filteredContacts1}
                      getOptionLabel={(option) => option.name}
                      disableCloseOnSelect
                      onChange={(event, value) =>
                        handleContactSelection(value as Contact[])
                      }
                      value={selectedContacts}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}({option.phoneNumber})
                        </li>
                      )}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.label}
                          variant="outlined"
                        />
                      )}
                      ListboxComponent={VirtualListbox}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label={field.label}
                      variant="outlined"
                      value={typedFormData[field.label]}
                      onChange={(e) =>
                        handleGroupNameChange(e.target.value)}
                      style={{ width: "100%", margin: "0" }}
                      error={field.label === "Name" && groupNameError !== ""}
                      helperText={field.label === "Name" && groupNameError}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              fontWeight: "bold",
              width: "15%",
              height: "42px",
              marginBottom: "25px",
              backgroundColor: "#D32F2F",
              color: "white",
              marginRight: "5%",
              marginTop: "1%",
              "&:hover": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
              "&:focus": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
            }}
          >
            {CONTACTS.CLOSE}
          </Button>
          <PheonixButton
            onClick={onConfirm}
            color="primary"
            sx={{ right: 20, marginBottom: "20px" }}
            disabled={!isSavedEnabled}
          >
            {confirmButtonText}
          </PheonixButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PheonixGroupModal;
