import React, { ChangeEvent, useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import PheonixComposeModalProps from "../interfaces/PheonixComposeModal";
import PheonixButton from "./PheonixButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import { Container } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { ERROR_MESSAGES } from "../Constant";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Contact } from "../interfaces/PheonixContact";
import { api } from "../api";
import CloseIcon from "@mui/icons-material/Close";
import { close } from "../Constant";
import VirtualListbox from "./PheonixVirtual";

const PheonixComposeModal = <T,>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  isSwitchSelected,
  onSwitchStateChanged,
}: PheonixComposeModalProps<T>) => {
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [allContacts, setAllContacts] = useState<Contact[]>([]);
  const [allGroups, setAllGroups] = useState<Contact[]>([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isGroupSwitchSelected, setGroupSwitchSelected] = useState(false);

  useEffect(() => {
    if (open) {
      setTextFieldValue("");
      setSelectedContacts([]);
      setGroupSwitchSelected(false);
      fetchContacts();
      fetchGroupData();
    }
  }, [open]);

  const fetchContacts = async () => {
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
          query GetContact {
            getContact {
              id
              name
              phoneNumber
              address 
            }
          }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const contactsData = response.data.data.getContact;
      setAllContacts(contactsData);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const fetchGroupData = async () => {
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
              query GetGroups {
                getGroups {
                    id
                    name
                    contacts {
                      id
                      name
                      phoneNumber
                    }
                  }
                }
              `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const groupsWithData = response.data.data.getGroups.filter((group: any) => group.contacts.length > 0);

    setAllGroups(groupsWithData);

    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR);
    }
  };

  const handleConfirm = () => {
    onConfirm(selectedContacts, textFieldValue);
  };
  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
  };

  const typedFormData = formData as Record<string, string>;

  const isTextFieldFilled = textFieldValue.trim() !== "";

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setGroupSwitchSelected(isChecked);
    onSwitchStateChanged?.(isChecked);
    if (!isChecked) {
      setSelectedContacts([]);
    } else if (isChecked) {
      setSelectedContacts([]);
    }
    setTextFieldValue("");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "white",
            width: "70%",
            height: "589px",
            padding: "3px 30px",
            boxShadow: "0px 4px 4px 0px #00000040",
            border: "1px solid #0000001F",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            height: "80px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 60,
            top: 40,
            color: "#000000",
          }}
        >
          <CloseIcon sx={{ color: "#000000" }} />
        </IconButton>
        <DialogContent>
          <DialogContentText style={{ marginTop: "16px" }}>
            <Grid container spacing={3}>
              {fields.map((field) => (
                <Grid item xs={12} key={field.label}>
                  {field.type === "autocomplete" ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Autocomplete
                        multiple={true}
                        id={field.label.toLowerCase().replace(/\s/g, "-")}
                        options={
                          isGroupSwitchSelected ? allContacts : allGroups
                        }
                        getOptionLabel={(option) => option.name}
                        disableCloseOnSelect
                        onChange={(event, value) =>
                          setSelectedContacts(value as Contact[])
                        }
                        value={selectedContacts}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        style={{ width: "65%", marginLeft: "2%" }}
                        renderInput={(params) => (
                          <TextField {...params} label={field.label} />
                        )}
                        ListboxComponent={VirtualListbox}
                      />
                      <Container
                        sx={{
                          width: "26%",
                          height: "55px",
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                        }}
                      >
                        <FormGroup
                          aria-label="position"
                          row
                          style={{ marginLeft: "2%", marginTop: "10%" }}
                        >
                          <FormControlLabel
                            value="start"
                            control={
                              <Switch
                                color="primary"
                                onChange={handleSwitchChange}
                              />
                            }
                            label={
                              isGroupSwitchSelected ? "Individual" : "Group"
                            }
                            labelPlacement="start"
                            style={{ marginLeft: "-15%" }}
                            checked={isGroupSwitchSelected}
                          />
                        </FormGroup>
                      </Container>{" "}
                    </div>
                  ) : (
                    <TextField
                      margin="dense"
                      label="Type here"
                      fullWidth
                      multiline
                      rows={8}
                      sx={{
                        width: "95%",
                        marginLeft: "2%",
                      }}
                      value={textFieldValue}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setTextFieldValue(e.target.value)
                      }

                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            onClick={onClose}
            sx={{
              fontWeight: "bold",
              width: "15%",
              height: "42px",
              marginBottom: "25px",
              marginTop:"-1%",
              backgroundColor: "#D32F2F",
              color: "white",
              "&:hover": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
              "&:focus": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
            }}
          >
            {close.CLOSE}
            </Button>

          <PheonixButton
            onClick={handleConfirm}
            color="primary"
            sx={{ marginBottom: "30px", marginRight: "40px" }}
            disabled={!isTextFieldFilled || selectedContacts.length === 0}
          >
            {confirmButtonText}
          </PheonixButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PheonixComposeModal;
