import React, { useState, useEffect, Suspense } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "../index.css";
import Header from "../components/Header";
import { CONTACTS, MESSAGES, Tabs, title } from "../Constant";
import PheonixBox from "../components/PheonixBox";
import PheonixButton from "../components/PheonixButton";
import axios from "axios";
import { api } from "../api";
import { ERROR_MESSAGES } from "../Constant";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Button, Chip, IconButton, List, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixSnackbar from "../components/PheonixSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import PheonixTable from "../components/PheonixTable";
import PheonixDataTable from "../interfaces/PheonixDataTable";
import PheonixGroupModal from "../components/PheonixGroupModels";
import { GroupFormData } from "../interfaces/PheonixGroupFormData";
import { AddContactFormData, Contact } from "../interfaces/PheonixAddForm";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PheonixSearch from "../components/PheonixSearch";
import PheonixDeleteModal from "../components/PheonixDeleteModal";
import Contacts from "./Contacts";
import PheonixFooter from "../components/PheonixFooter";
import SkeletonLoader, { GroupSkeletonLoader } from "../components/PheonixSkeletonLoader";
import PheonixGroupTable from "../components/PheonixGroupTable";
import { PheonixDataGrid } from "../components/PheonixDataGrid";
import VirtualListbox from "../components/PheonixVirtual";

const Groups: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [apiresponse, setApiresponse] = useState<any[]>([]);
  const [selectedGroupName, setSelectedGroupName] = useState<string | null>(
    null
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [filteredCustomersData, setFilteredCustomersData] = useState<Contact[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [deletemodal, setDeleteModal] = useState(false);
  const [contactDeleteModalOpen, setContactDeleteModalOpen] = useState(false);
  const [groupCreationSuccess, setGroupCreationSuccess] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [groupFormData, setGroupFormData] = useState<GroupFormData>({
    Name: "",
    contactIds: [],
  });
  const [addContactFormData, setaddContactFormData] =
    useState<AddContactFormData>({
      "Group Name": "",
      contactIds: [],
    });
    const [loading, setLoading] = useState(true);
  const selectedContactObjects = contacts.filter((contact: Contact) =>
    selectedContacts.includes(contact.id)
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseModal = () => {
    setIsGroupModalOpen(false);
    setIsAddContactModalOpen(false);
  };

  const handleCreateGroupClick = () => {
    setIsGroupModalOpen(true);
  };

  const handleDelete = async (groupName: string | null) => {
    const query = `
  mutation DeleteGroup($name: String!) {
    deleteGroup(name: $name) {
      success
      message
    }
  }
  `;
    const variables = {
      name: groupName,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const { success, message } = response.data.data.deleteGroup;

      if (success) {
        setSnackbarMessage(MESSAGES.GROUP_DELETED);
        setSnackbarOpen(true);
        setSelectedGroupId(null);
        setSelectedGroupName(null);
        setContacts([]);
        setDeleteModal(false);
        fetchData();
      } else {
        console.error(ERROR_MESSAGES.ERROR);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR);
    }
  };

  //GET API For Group
  const fetchData = async () => {
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
          query GetGroups {
            getGroups {
                id
                name
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setApiresponse(response.data.data.getGroups);
      setLoading(false);
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedGroupName]);

  useEffect(() => {
    if (selectedGroupId) {
      fetchContacts(selectedGroupId);
    }
  }, [selectedGroupId]);

  const fetchContacts = async (groupId: string) => {
    try {
      const response = await axios.post(api.baseUrl, {
        query: `
        query GetGroupById($id: ID!) {
          getGroupById(id: $id) {
            id
            name
            contacts {
              id
              name
              phoneNumber
              address
            }
          }
        }
        `,
        variables: { id: groupId },
      });

      const groupData = response.data.data.getGroupById;

      if (groupData) {
        setContacts(response.data.data.getGroupById[0].contacts);
      } else {
        console.error(MESSAGES.ERROR);
      }
      setLoading(false);
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };

  useEffect(() => {}, [apiresponse]);

  const handleDeleteContact = async (contactId: string, groupId: string) => {
    const query = `
    mutation DeleteContactToGroup($contactId: ID!, $groupId: ID!) {
      deleteContactToGroup(contactId: $contactId, groupId: $groupId) {
        success
        message
      }
    }
    `;
    const variables = {
      contactId: contactId,
      groupId: groupId,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const { success, message } = response.data.data.deleteContactToGroup;

      if (success) {
        setSnackbarMessage(CONTACTS.GROUP_DELETE_CONTACT);
        setSnackbarOpen(true);
        const updatedContacts = contacts.filter((contact) => contact.id !== contactId);
        setContacts(updatedContacts);
        setContactDeleteModalOpen(false);
      } else {
        console.error(ERROR_MESSAGES.ERROR);
        setSnackbarMessage(ERROR_MESSAGES.ERROR);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR);
    }
  };

  const handleContactSelection = (contactId: string) => {
    if (selectedContacts.includes(contactId)) {
      setSelectedContacts(selectedContacts.filter((id) => id !== contactId));
    } else {
      setSelectedContacts([...selectedContacts, contactId]);
    }
  };

  const handleCreateGroup = async (name: string, contactIds: string[]) => {
    const query = `
      mutation CreateGroupWithContacts($name: String!, $contactIds: [ID!]!) {
        createGroupWithContacts(name: $name, contactIds: $contactIds) {
          success
          message
        }
      }
    `;
    const variables = {
      name: name,
      contactIds: contactIds,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const { success, message } = response.data.data.createGroupWithContacts;

      if (success) {
        setSnackbarMessage(CONTACTS.GROUP_CREATE_CONTACT);
        setSnackbarOpen(true);
        setGroupCreationSuccess(false);
        fetchData();
      } else {
        if (message === "Group already exists") {
          setGroupCreationSuccess(false);
          setSnackbarMessage(CONTACTS.GROUP_ALREADY_EXISTS);
          setSnackbarOpen(true);
        } else {
          console.error(ERROR_MESSAGES.ERROR);
        }
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleConfirmCreateGroup = () => {
    setGroupCreationSuccess(true);
    handleCreateGroup(groupFormData.Name, groupFormData.contactIds);
    setIsGroupModalOpen(false);
    setGroupFormData({ Name: "", contactIds: [] });
    fetchData();
  };
  
  const handleAddContactTogroupClick = () => {
    setIsAddContactModalOpen(true);
    setaddContactFormData({
      ...addContactFormData,
      "Group Name": selectedGroupName || "",
    });
  };

  const handleAddContactToGroup = async (
    groupId: string,
    contactIds: string[]
  ) => {
    const query = `
      mutation AddContactToGroup($groupId: ID!, $contactIds: [ID!]!) {
        addContactToGroup(groupId: $groupId, contactIds: $contactIds) {
          success
          message
        }
      }
    `;

    const variables = {
      groupId: groupId,
      contactIds: contactIds,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query: query,
        variables: variables,
      });

      const { success, message } = response.data.data.addContactToGroup;

      if (success) {
        setSnackbarMessage(CONTACTS.ADD_CONTACT_GROUP);
        setSnackbarOpen(true);
        fetchContacts(groupId);
      } else {
        if (message === "Contact already exist in the group") {
          setSnackbarMessage(CONTACTS.CONTACT_ALREADY_EXISTS_IN_GROUP);
          setSnackbarOpen(true);
        } else {
          console.error(ERROR_MESSAGES.ERROR);
        }
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };

  const handleConfirmAddGroup = () => {
    setIsAddContactModalOpen(false);
    if (selectedGroupId && addContactFormData.contactIds.length > 0) {
      handleAddContactToGroup(selectedGroupId, addContactFormData.contactIds);
      setaddContactFormData({ "Group Name": "", contactIds: [] });
    } else {
      console.error(ERROR_MESSAGES.ERROR);
    }
  };

  const handleSearch = (searchQuery: string) => {
    const filteredData = contacts.filter((order) =>
      Object.values(order).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredCustomersData(filteredData);
    setCurrentPage(1);
  };

  const renderRow = (index: number, style: React.CSSProperties) => {
    const group = apiresponse[index];
    const isSelected = selectedGroupName === group.name;
    
    return (
      <ListItem
        style={{
          ...style,
          backgroundColor: isSelected ? "#2196F314" : "inherit"
        }}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton
          onClick={() => {
            setSelectedGroupName(group.name);
            setSelectedGroupId(group.id);
          }}
        >
          <ListItemText primary={`${group.name}`} />
        </ListItemButton>
        {selectedGroupName === group.name && (
          <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => setDeleteModal(true)}>
               <DeleteIcon />
          </IconButton>
          </Tooltip>
        )}
      </ListItem>
    );
  };

  const openDeleteContactModal = (contactId: string) => {
    setSelectedContactId(contactId);
    setContactDeleteModalOpen(true);
  };

  const closeDeleteContactModal = () => {
    setContactDeleteModalOpen(false);
  };

  const column = [
    { field: "name", headerName: "Name", width: 220 },
    { field: "phoneNumber", headerName: "Contact Number" , width: 180},
    { field: "address", headerName: "Address" , width: 250 },
    {
      field: "actions",
      headerName: "Action",
      align: "center",
      minWidth: 100,
      renderCell: (row: PheonixDataTable) => {
        return (
          <Box style={{ flexDirection: "row" }}>
 <Tooltip title="Delete">
<IconButton 
 onClick={() => openDeleteContactModal(row.id)} 
 sx={{marginLeft:"-50%" }}>

              <PersonRemoveOutlinedIcon sx={{ color: "#F71313B2" }} />
            </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      style={{ display: "flex", backgroundColor: "#F1F1F1", height: "100vh" }}
    >
      <Box
        style={{
          marginLeft: isSidebarOpen ? 240 : 0,
          transition: "margin-left 0.3s",
          width: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: "-45px",
            marginLeft: "20px",
            fontSize: "22px",
            fontWeight: "800",
            lineHeight: "24px",
            letterSpacing: "0.15px",
          }}
        >
          {Tabs.GROUPS_TAB}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              width: "29%",
              height: "580px",
              backgroundColor: "white",
              marginTop: "3%",
              marginLeft: "3%",
              borderRadius: "30px 0px 0px 30px",
              boxShadow: "4px 4px 4px 0px #00000040",
              position: "relative",
              alignItems: "center", 
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <PheonixButton
              variant="contained"
              onClick={handleCreateGroupClick}
              size="large"
              sx={{
                fontWeight: "bold",
                marginTop: "10%"
              }}
            >
              {MESSAGES.CREATE_GROUP}
            </PheonixButton>
            {groupCreationSuccess && (
       <Chip
        label={MESSAGES.GROUP_CREATE_IN_PROGRESS}
        variant="outlined"
        sx={{ marginTop: "3%", marginLeft: "auto", 
        marginRight: "auto", }}
      />
    )}

    {loading ?
    <Chip
        label={MESSAGES.GROUPS_DATA}
        variant="outlined"
        sx={{  marginTop: "3%", marginLeft: "auto", 
        marginRight: "auto", }}
    />
    :null
}

{loading ? <GroupSkeletonLoader />
  : (
            <FixedSizeList
              height={380}
              width="calc(105% - 20px)"
              itemSize={46}
              itemCount={apiresponse.length}
              overscanCount={5}
              style={{ marginTop: "10px" }}
            >
              {({ index, style }) => renderRow(index, style)}         
            </FixedSizeList>
  )}

{!loading && apiresponse.length === 0 && !groupCreationSuccess && (
  <Chip label="No records" variant="outlined" style={{ marginTop: "-170%" }} />
)}

          </Box>
          <Box
            sx={{
              width: "63%",
              height: "580px",
              backgroundColor: "white",
              marginTop: "3%",
              marginLeft: "2%",
              borderRadius: "0px 30px 30px 0px",
              boxShadow: "4px 4px 4px 0px #00000040",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>

              <Button
                variant="contained"
                onClick={handleAddContactTogroupClick}
                size="large"
                disabled={!selectedGroupName}
                sx={{
                  fontWeight: "bold",
                  marginTop: "10%",
                  marginLeft: "65%",
                  width:"200px",
                  '@media (max-width: 3060px)': { 
                    marginTop: "10%", 
                    marginBottom: "-5%",
                    width:"auto"
                },
                  '@media (max-width: 2560px)': { 
                    marginTop: "5%",
                    marginBottom:"1%"
                },
                  '@media (max-width: 1440px)': {
                    marginTop: "5%", 
                    width:"30%",
                    marginLeft:"450px"
                },
                }}
              >
                {CONTACTS.ADD_CONTACTS_TO_GROUP}
              </Button>
            </Box>
            <PheonixDataGrid
              columns={column}
              rows={filteredCustomersData.length > 0 ? filteredCustomersData : contacts}
              style={{ border: '0px', marginLeft: '20px'}}
            />
          </Box>
        </Box>
        <PheonixSnackbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
            fontSize: "24px",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          vertical="top"
          horizontal="center"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          sx={{
            width: "320px",
            height: "42px",
            "& .MuiSnackbarContent-root": {
              background: " #323232",
            },
          }}
        />

        <PheonixGroupModal
          open={isAddContactModalOpen}
          onClose={handleCloseModal}
          title={CONTACTS.ADD_CONTACTS_TO_GROUP}
          fields={[
            {
              label: "Group Name",
              disabled: true,
            },
            { label: "Add Contacts", type: "autocomplete" },
          ]}
          onConfirm={handleConfirmAddGroup}
          confirmButtonText={CONTACTS.SAVE}
          formData={addContactFormData}
          isEditModal={false}
          setFormData={setaddContactFormData}
          selectedContacts={selectedContactObjects}
          onContactClick={handleContactSelection}
          contacts={contacts}
        />

        <PheonixGroupModal
          open={isGroupModalOpen}
          onClose={handleCloseModal}
          title={CONTACTS.CREATE_GROUP}
          fields={[
            {
              label: "Name",
              type: "text",
              value: groupFormData.Name,
              onChange: (e) =>
                setGroupFormData({ ...groupFormData, Name: e.target.value }),
            },
            { label: "Add Contacts", type: "autocomplete" },
          ]}
          onConfirm={handleConfirmCreateGroup}
          confirmButtonText={CONTACTS.SAVE}
          formData={groupFormData}
          isEditModal={false}
          setFormData={setGroupFormData}
          selectedContacts={selectedContactObjects}
          onContactClick={handleContactSelection}
        />
        <PheonixDeleteModal
          open={deletemodal}
          onClose={() => setDeleteModal(false)}
          onConfirm={() => handleDelete(selectedGroupName)}
          entity={title.GROUP}
        />
          <PheonixDeleteModal
            open={contactDeleteModalOpen}
            onClose={closeDeleteContactModal}
            onConfirm={() => handleDeleteContact(selectedContactId || "", selectedGroupId || "")}
            entity={title.CONTACT}
      />
        <PheonixFooter />
      </Box>
    </Box>
  );
};

export default Groups;
