import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PheonixDataGridProps from "../interfaces/PheonixDataGird";

export const PheonixDataGridContact: React.FC<PheonixDataGridProps> = ({
  rows,
  columns,
  paginationMode,
  components,
  style,
  pageSizeOptions,
  paginationModel,
  onPaginationModelChange,
  rowCount,
  ...props
}) => {
  return (
    <div style={{ width: "100%", display: "contents" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        rowCount={rowCount}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
        style={style}
        disableRowSelectionOnClick
        {...props}
      />
    </div>
  );
};
