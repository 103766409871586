import React, { Suspense, useEffect, useState } from "react";
import { CONTACTS, ERROR_MESSAGES, Tabs, Text, title, MESSAGES } from "../Constant";
import Box from "@mui/material/Box";
import "../index.css";
import { Button, IconButton, Chip, CircularProgress, Stack} from "@mui/material";
import PheonixTable from "../components/PheonixTable";
import PheonixSearch from "../components/PheonixSearch";
import PheonixComposeModal from "../components/PheonixComposeModal";
import PheonixFooter from "../components/PheonixFooter";
import axios from "axios";
import { api } from "../api";
import { Contact } from "../interfaces/PheonixContact";
import PheonixSnackbar from "../components/PheonixSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import moment  from 'moment';
import MessageSkeletonLoader from "../components/PheonixMessageLoader";
import { PheonixDataGrid } from "../components/PheonixDataGrid";
import PheonixDataTable from "../interfaces/PheonixDataTable";

interface Row {
  SuccessCount: number;
  FailedCount: number;
}

const Message: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EditModal, setEditmodal] = useState(false);
  const [switchSelected, setSwitchSelected] = useState(false);
  const [searchdata, setSearchdata] = useState<Contact[]>([]);
  const [getresponse, setGetresponse] = useState<Contact[]>([]);
  const [Snackbaropen, setSnackbaropen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [apiresponse, setApiresponse] = useState<any[]>([]);
  const [composeLoading, setComposeLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [stayPage, setStayPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  
  const [composeFormData, setComposeFormData] = useState({
    TO: "",
    TypeHere: "",
  });

  const handleSnackbarClose = () => {
    setSnackbaropen(false);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const ModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleComposeClick = () => {
    setComposeLoading(true);
    ModalOpen();
  }
  
  const column = [
    { field: "sno", headerName: "S.No" },
    { field: "date", headerName: "Date", width: 300 },
    { field: "recipient", headerName: "Recipient", width: 350,},
    { field: "message", headerName: "Message" , width: 350},
    { field: "status", 
      headerName: "Status", 
      width: 200, 
      renderCell: (row: Row) => {
      return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Chip
          color="success"
          //@ts-ignore
          label={`Success: ${row.row.SuccessCount}`}
          sx={{ height: "24px" }}
        />
        <div style={{ flexGrow: -11 }} />
        <Chip
        //@ts-ignore
          label={`Failed: ${row.row.FailedCount}`}
          sx={{ height: "24px", backgroundColor: "#D32F2F", color: "#FFFFFF" }}
        />
      </Stack>
    );
  }
    }
  ];


  const handleSearch = (searchQuery: string) => {
    const query = searchQuery.toLowerCase().trim();

    if (query === "") {
      setSearchdata([]);
      setCurrentPage(1);
      return;
    }

    const filteredContacts = getresponse.filter((contact) =>
      Object.values(contact).some(
        (value) =>
          typeof value === "string" && value.toLowerCase().includes(query)
      )
    );

    const filteredContactsWithIndex = filteredContacts.map(
      (item: Contact, index: number) => ({
        ...item,
        sno: (index + 1).toString(),
      })
    );

    setSearchdata(filteredContactsWithIndex);
  };

  const handleGetMessage = async () => {
    try {

      const response = await axios.post(
        api.baseUrl,
        {
          query: `
            query GetMessage{
              getMessage{
                id 
                message
                date
                recipients
                FailedCount
                SuccessCount
              }
            }
          `,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const responseDataWithIndex = response.data.data.getMessage.map(
        (item: { message: string; date: string; recipients: string[] }, index: number) => {

          const formattedDate = moment(item.date, 'MM/DD/YYYY hh:mm A').format('DD/MM/YYYY hh:mm A');

          return {
            ...item,
            sno: (index + 1).toString(),
            recipient: item.recipients.join(', '),
            date: formattedDate,
          };
        }
      );

      setGetresponse(responseDataWithIndex);
      setLoading(false);
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR);
    }
  };

  useEffect(() => {
    handleGetMessage();
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };


  const handleSwitchChange = (isChecked: boolean) => {
    setSwitchSelected(isChecked);
  };

  const handleConfirm = async (contacts: Contact[], text: string) => {
    if (switchSelected) {
      const query = `
        mutation SendSMS($message: String!, $contactIds: [ID!]!) {
            sendSMS(message: $message, contactIds: $contactIds) {
                success
                message
            }
        }
    `;

      const variables = {
        message: text,
        contactIds: contacts.map((contact) => contact.id),
      };

      try {

        setComposeLoading(true);

        const response = await axios.post(api.baseUrl, {
          query,
          variables,
        });

        const { success, message } = response.data.data.sendSMS;

        if (success) {
          setSnackbarMessage(Text.MESSAGE_SENT);
          setTimeout(() => {
            setSnackbaropen(true);
            handleGetMessage();
          }, 60000);
          setTimeout(() => {
            handleGetMessage();
          }, 80000);
          setIsModalOpen(false);
          setEditmodal(false);
          handleGetMessage();
        } else {
          console.error(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        console.error(ERROR_MESSAGES.ERROR);
      }finally {
        setTimeout(() => {
          setComposeLoading(false);
          handleGetMessage();
        }, 60000);
      }
    } else {
      const query = `
        mutation SendSMSToGroups($message: String!, $groupIds: [String!]!) {
          sendSMSToGroups(message: $message, groupIds: $groupIds) {
                success
                message
            }
        }
    `;

      const variables = {
        message: text,
        groupIds: contacts.map((contact) => contact.id),
      };

      try {

        setComposeLoading(true);

        const response = await axios.post(api.baseUrl, {
          query,
          variables,
        });

        const { success, message } = response.data.data.sendSMSToGroups;
        if (success) {
          setSnackbarMessage(Text.MESSAGE_SENT);
          setTimeout(() => {
            setSnackbaropen(true);
            handleGetMessage();
          }, 60000);
          setTimeout(() => {
            handleGetMessage();
          }, 80000);
          setIsModalOpen(false);
          setEditmodal(false);
          handleGetMessage();
        } else {
          console.error(ERROR_MESSAGES.ERROR);
        }
        
      } catch (error) {
        console.error(ERROR_MESSAGES.ERROR);
      }finally {
        setTimeout(() => {
          setComposeLoading(false);
          handleGetMessage();
        }, 60000);
      }
    }
  };

  return (
    <Box
      style={{ display: "flex", backgroundColor: "#F1F1F1", height: "100vh" }}
    >
      <Box
        style={{
          marginLeft: isSidebarOpen ? 240 : 0,
          transition: "margin-left 0.3s",
          width: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: "-45px",
            marginLeft: "1px",
            fontSize: "22px",
            fontWeight: "800",
            lineHeight: "24px",
            letterSpacing: "0.15px",
          }}
        >
          {Tabs.MESSAGE_TAB}
        </Box>

        <Box
          sx={{
            width: "94%",
            height: "80px",
            backgroundColor: "white",
            padding: "16px",
            gap: "16px,",
            borderRadius: "20px 20px 0px 0px",
            marginLeft: "2%",
            marginTop: "4%",
            boxShadow: "0px 3px 4px 0px #00000040",
            border: "1px solid #0000001F",
            '@media (min-width: 2560px)': {
              width: "94.5%",
            },
            '@media (max-width: 2560px) and (min-width: 1440px)': {
              width: "93.5%",
            },
            '@media (max-width: 1440px) and (min-width: 1024px)': {
              width: "93%",
            },
          }}
        >

          <Button
            variant="contained"
            onClick={ModalOpen}
            sx={{
              marginLeft: "90%",
              marginTop: "2%",
              "@media (max-width: 2800px)": {
                marginLeft: "90%",
                marginTop: "5%",
              },
              "@media (max-width: 2560px)": {
                marginLeft: "90%",
                marginTop: "2%",
              },
              "@media (max-width: 1440px)": {
                marginLeft: "90%",
                marginTop: "2%",
              },
              "@media (max-width: 1024px)": {
                marginLeft: "80%",
                marginTop: "2%",
              },
              "@media (max-width: 768px)": {
                marginLeft: "60%",
                marginTop: "2%",
              },
            }}
            disabled={composeLoading}
          >
            {composeLoading ? (
              <CircularProgress size={24}/>
            
            ) : (
              Text.COMPOSE
            )}
          </Button>
          {composeLoading && (
            <Chip
              label= {MESSAGES.SENDING_SMS_IN_PROGRESS} 
              variant="outlined"
              sx={{ marginLeft: "60%" ,marginTop: '-5%' }}
            />
          )}

          { loading ?
          <Chip
          label={MESSAGES.HOME_DATA}
          variant="outlined"
          sx={{  marginTop: "-5%", marginLeft: "30%", marginRight: "auto", }}
          />
          : null
          }
        </Box>

        <Box
          sx={{
            width: "95.5%",
            height: "417px",
            backgroundColor: "white",
            marginLeft: "2%",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            display: "flex",
            flexDirection: "column",
            marginTop: "1.5%",
          }}
        >

        {loading ? 
          <MessageSkeletonLoader />
         : (
            <PheonixDataGrid
              columns={column}
              rows={searchdata.length > 0 ? searchdata : getresponse}
              style={{ border: '0px', marginLeft: '20px'}}
            />
        )}

        </Box>

        <PheonixComposeModal
          open={isModalOpen}
          onClose={handleCloseModal}
          title={title.COMPOSE}
          fields={[
            { label: "To", type: "autocomplete" },
            { label: "Text", type: "text" },
          ]}
          onConfirm={handleConfirm}
          confirmButtonText={CONTACTS.SEND}
          isEditModal={false}
          isSwitchSelected={switchSelected}
          onSwitchStateChanged={handleSwitchChange}
          formData={composeFormData}
          setFormData={setComposeFormData}
          selectedContacts={[]}
        />
        <PheonixSnackbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
            fontSize: "24px",
          }}
          open={Snackbaropen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          vertical="top"
          horizontal="center"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          sx={{
            width: "320px",
            height: "42px",
            "& .MuiSnackbarContent-root": {
              background: " #323232",
            },
          }}
        />

        <PheonixFooter />
      </Box>
    </Box>
  );
};

export default Message;
