import React, { useState, useEffect, ChangeEvent } from "react";
import PheonixBox from "../components/PheonixBox";
import PheonixTextField from "../components/PheonixTextField";
import PheonixButton from "../components/PheonixButton";
import PheonixSnackbar from "../components/PheonixSnackbar";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { api } from "../api";
import { ERROR_MESSAGES, MESSAGES } from "../Constant";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSession } from "../contexts/SessionContext";

const LoginScreen: React.FC = () => {
  const navigation = useNavigate();
  const { isLoggedIn, login } = useSession();
  const [email, setemail] = useState(localStorage.getItem("email") || "");
  const [password, setpassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [emailError, setEmailError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [pasErrorMessage, setPasErrorMessage] = useState("");
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [success, setSuccess] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const paperStyle = isMobile
    ? { padding: "20px", width: "auto", height: "auto",marginBottom:"-200%" }
    : { padding: "50px", width: "375px", height: "550px",marginBottom: "-42%",marginTop:"2.5%"};

  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setemail(newEmail);
  };

  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailFocus = () => {
    setErrorMessage("");
  };

  const handleEmailBlur = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };

  const handlePasswordBlur = () => {
    const minLength = 8;
    const passlength = password.length >= minLength;
    if (!passlength) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
    } else {
      setPasErrorMessage("");
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlePasswordFocus = () => {
    setErrorMessage("");
    setPasErrorMessage("");
  };

  const handleLogin = async () => {

    if (!isValidEmail(email)) {
      setEmailError(ERROR_MESSAGES.VALID_EMAIL_ERROR);
      setSnackbarMessage("Invalid email address");
      setSnackbarOpen(true);
      return;
    }

    if (!isValidPassword(password)) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
      return;
    }

    if (!email || !password) {
      setEmailError(ERROR_MESSAGES.BOTH_FIELD);
      return;
    }

    const query = `mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          message
          success
        }
      }`;

    const variables = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const { success, message } = response.data.data.login;

      if (success) {
        setSnackbarMessage("Login Success");
        login();
        setSuccess(true);
        setSnackbarOpen(true);

        setTimeout(() => {
          setSnackbarOpen(false);
          navigation("/Home");
        }, 3000);
      } else {
        if (message.includes("email")) {
          setSnackbarMessage("Invalid Email");
          setSuccess(false);
          setSnackbarOpen(true);
        } else if (message.includes("password")) {
          setSnackbarMessage("Invalid Password");
          setSuccess(false);
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage("Invalid Email");
          setSuccess(false);
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };

  useEffect(() => {
    const emailValid = isValidEmail(email);
    const passwordValid = isValidPassword(password);
    if (!emailValid || !passwordValid) {
      setIsLoginButtonDisabled(false);
    } else {
      setIsLoginButtonDisabled(true);
    }
  }, [email, password]);

  const isValidPassword = (password: string): boolean => {
    const minLength = 8;
    return password.length >= minLength;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <PheonixBox style={{ paddingTop: "20px" }}>
      <Paper elevation={5} style={paperStyle}>
        <Typography
          style={{
            textAlign: "center",
            marginTop: "50px",
            padding: isMobile ? "15px" : "7px",
            fontSize: isMobile ? "20px" : "24px",
            fontWeight: "400",
            fontFamily: "Roboto",
            color: theme.palette.primary.main,
          }}
        >
          {MESSAGES.LOGINPAGE_NAME}
        </Typography>

        <Typography
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "60px",
            marginBottom: "60px",
            fontWeight: "700",
            fontFamily: "Roboto",
            color: theme.palette.primary.main,
          }}
        >
          {MESSAGES.LOGIN_NAME}
        </Typography>

        <Stack spacing={5} direction="column">
          <PheonixTextField
            id="outlined-basic"
            type="email"
            value={email}
            placeholder="Email"
            label="Email"
            variant="outlined"
            onChange={handleemailChange}
            onFocus={handleEmailFocus}
            onBlur={handleEmailBlur}
            error={!!errorMessage}
            helperText={errorMessage}
          />

          <PheonixTextField
            id="outlined-basic"
            type={showPassword ? "text" : "password"}
            value={password}
            placeholder="Password"
            label="Password"
            variant="outlined"
            onChange={handlepasswordChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            error={Boolean(pasErrorMessage)}
            helperText={pasErrorMessage || ""}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ cursor: "pointer", marginTop: "0px" }}
                onClick={handleTogglePassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            }
            onKeyPress={handleKeyPress} 
          />
          <PheonixSnackbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "40px",
              fontSize: "24px",
            }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            vertical="top"
            horizontal="center"
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            sx={{
              width: "320px",
              height: "42px",
              "& .MuiSnackbarContent-root": {
                background: " #323232",
              },
            }}
          />
          <Stack direction="column" spacing={50}>
            <PheonixButton
              onClick={handleLogin}
              disabled={!isLoginButtonDisabled}
            >
              {MESSAGES.LOGIN_BUTTON_NAME}
            </PheonixButton>
          </Stack>
        </Stack>
      </Paper>
    </PheonixBox>
  );
};

export default LoginScreen;
