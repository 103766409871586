import { useTheme } from "@mui/material/styles";
import PheonixSideBar from "../components/PheonixSideBar";
import SidebarProps from "../interfaces/SideBarProps";
import MenuIcon from "@mui/icons-material/Menu";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import List from "@mui/material/List";
import { Tooltip } from "@mui/material";

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const handleMenuClick = () => {
    onToggle();
  };

  const iconComponent = isOpen ? <ChevronLeftIcon /> : <MenuIcon />;

  const menuItems = [
    {
      text: "Home",
      icon: isOpen ? (
        <HomeOutlinedIcon sx={{ color: "white" }} />
      ) : (
        <Tooltip title="Home">
          <HomeOutlinedIcon sx={{ color: "white" }} />
        </Tooltip>
      ),
      to:"/Home",
    },
    {
      text: "Contacts",
      icon: isOpen ? (
         <ContactPhoneOutlinedIcon sx={{ color: "white" }} />
         ) : (
          <Tooltip title="Contacts">
             <ContactPhoneOutlinedIcon sx={{ color: "white" }} />
             </Tooltip>
      ),
      to: "/Contacts",
    },
    {
      text: "Groups",
      icon: isOpen ? (
        <GroupsOutlinedIcon sx={{ color: "white" }} />
        ) : (
         <Tooltip title="Groups">
            <GroupsOutlinedIcon sx={{ color: "white" }} />
            </Tooltip>
        ),
      to: "/Groups",
    },
    {
      text: "Message",
      icon: isOpen ? (
        <ChatOutlinedIcon sx={{ color: "white" }} />
        ) : (
         <Tooltip title="Message">
            <ChatOutlinedIcon sx={{ color: "white" }} />
            </Tooltip>
        ),
      to: "/Message",
    },
    {
      text: "Logoff",
      icon: isOpen ? (
        <LogoutOutlinedIcon sx={{ color: "white" }} />
        ) : (
         <Tooltip title="Logoff">
            <LogoutOutlinedIcon sx={{ color: "white" }} />
            </Tooltip>
        ),
      to: "/Logoff",
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isOpen ? 217 : 65,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "#59356C",
          fontSize: "16px",
          color: "white",
          width: isOpen ? 217 : 65,
          boxSizing: "border-box",
          transition: "width 0.2s ease-in-out, visibility 0.2s linear",
          overflow: "hidden",
        },
      }}
      open={isOpen}
      onClose={onToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div
        onClick={handleMenuClick}
        style={
          isOpen
            ? {
                position: "absolute",
                top: "27px",
                right: "30px",
                cursor: "pointer",
              }
            : {
                position: "absolute",
                top: "27px",
                left: "19px",
                cursor: "pointer",
              }
        }
      >
        <div
          onClick={onToggle}
          style={
            isOpen
              ? { position: "absolute", cursor: "pointer" }
              : {
                  position: "absolute",
                  cursor: "pointer",
                }
          }
        >
          {iconComponent}
        </div>
      </div>
      <List sx={{ top: "85px" }}>
        {menuItems.map((item, index) => (
          <PheonixSideBar
            key={index}
            icon={item.icon}
            text={item.text}
            to={item.to}
          />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
