import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const SkeletonLoader = () => {
  return (
    <Box width="100%" marginTop={5}>
  {[...Array(6)].map((_, index) => (
    <Box key={index} marginBottom={2}>
      <Skeleton variant="rectangular" height={50} />
    </Box>
  ))}
</Box>
  );
};

export default SkeletonLoader;

export const GroupSkeletonLoader = () => {
  return (
    <Box width="90%" marginBottom={2}  sx={{margin: 'auto', marginTop:"5%"}}>
  {[...Array(6)].map((_, index) => (
    <Box key={index} marginBottom={2}>
      <Skeleton variant="rectangular" height={50} />
    </Box>
  ))}
</Box>
  );
};