import * as React from "react";
import Box from "@mui/material/Box";
import { PheonixBoxProps } from "../interfaces/PheonixBox";
const PheonixBox: React.FC<PheonixBoxProps> = ({
  children,
  component = "section",
  style,
  sx = { width: "auto" },
}) => {
  return (
    <Box
      component={component}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        maxHeight: "100vh",
      }}
      style={style}
    >
      {children}
    </Box>
  );
};

export default PheonixBox;
