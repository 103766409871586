import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import LoginScreen from "./routes/Login";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Contacts from "./pages/Contacts";
import Message from "./pages/Message";
import Groups from "./pages/Groups";
import Summary from "./pages/Reports";
import Logoff from "./pages/Logoff";
import { SessionContextProvider, useSession } from "./contexts/SessionContext";

const NavigateToLogin = () => {
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (!loggedIn && location.pathname !== "/") {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return null;
};

function App() {
  return (
    <Router>
      <SessionContextProvider>
        <NavigateToLogin />
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route
            path="/Contacts"
            element={
              <Layout title="Contacts">
                <Contacts />
              </Layout>
            }
          />
          <Route
            path="/Message"
            element={
              <Layout title="Message">
                {" "}
                <Message />{" "}
              </Layout>
            }
          />
          <Route
            path="/Groups"
            element={
              <Layout title="Groups">
                {" "}
                <Groups />
              </Layout>
            }
          />
          <Route
            path="/Summary"
            element={
              <Layout title="Summary">
                <Summary />
              </Layout>
            }
          />
          <Route
            path="/Home"
            element={
              <Layout title="Home">
                <Home />
              </Layout>
            }
          />
          <Route
            path="/Logoff"
            element={
              <Layout title="Logoff">
                <Logoff />
              </Layout>
            }
          />
        </Routes>
      </SessionContextProvider>
    </Router>
  );
}

export default App;
