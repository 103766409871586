export const ERROR_MESSAGES = {
  ENTER_BOTH: "Please enter both email and password",
  VALID_EMAIL: "Please enter a valid email address",
  EMAIL_REQUIRED_ERROR: "Email field is required",
  PASSWORD_REQUIRED_ERROR: "Password field is required",
  VALID_EMAIL_ERROR: "Enter valid mail id",
  ERROR: "ERROR",
  BOTH_FIELD: "Enter both Email & Password",
  GENERIC_ERROR: "Login Failed",
  INVALID_PASSWORD: "Invalid password",
  PWD_LENGTH: "Password should be minimum of 8 characters",
  CONTACTS_NAME: "Please enter a valid name that contains only alphabets",
  CONTACTS_NUMBER: "Phone number should have only 10 digits",
  CONTACTS_SAMENUMBER: "Contact number already Exists",
  SNACKBAR_MESSAGE: "Contact Deleted Successfully",
  SNACKBAR_SUCCESS: "Contact Added Successfully",
  SNACKBAR_CSVFILE: "File size should be 3MB or below",
  SNACKBAR_CSVFILEERROR: "Error adding CSV file",
  NAME_EMPTY: "Name should not be Empty",
  NUMBER_EMPTY: "Mobile number Should not be empty",
  MODAL_SUCCESSFUL: "Updated Sucessfully",
  FOOTER_TXT: "Designed & Developed with ❤️ by ",
  PHEONIX_TXT: "Pheonixsolutions",
  DELETE_CONFIRMATION: "Delete Confirmation",
  DELETE_SUCCESSFULL: "Product deleted successfully",
  DELETE_MSG: "Are you sure you want to delete this ",
  DELETE: "Delete",
  TOTAL_MESSAGE_SENT: "Total Message Sent",
  NUMBER_FIELD: "Phone number should have only 10 digits",
  SESSION_ERROR: "useSession must be used within a SessionProvider",
  EMAIL_REG:"/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/",
  EMAIL_VAILD:" /^[^\s@]+@[^\s@]+\.[^\s@]+$/",
  GRAPHQL_ERROR:"GraphQL Error",
  SERVER_ERROR:"Server Error",
  NETWORK_ERROR:"Network Error",
};

export const MESSAGES = {
  LOGIN_BUTTON_NAME: "Login",
  SIGNUP_BUTTON_NAME: "Sign Up",
  EMAIL_LABEL: "Email",
  USERNAME_LABEL: "Username",
  PASSWORD_LABEL: "Password",
  LOGINPAGE_NAME: "KRISHNA JEWELLERY",
  LOGIN_NAME: "Login",
  ADD_CONTACT: "ADD CONTACT TO GROUP",
  CREATE_GROUP: "CREATE GROUP",
  GROUP_DELETED: "Group has been deleted successfully",
  ERROR: "Error fetching group details.",
  ADD_CONTACTS: "ADD CONTACT",
  UPLOAD: "UPLOAD CSV",
  EXPORT: "Export CSV",
  GROUP_CREATE_IN_PROGRESS:"Almost there! Your group will be ready in a few seconds",
  SENDING_SMS_IN_PROGRESS: "Sending SMS in progress.Please wait for 1 minute", 
  GROUPS_DATA:"Your group information will be arriving shortly",
  HOME_DATA:"Your recent message information will be arriving shortly",
  MESSAGE_DATA:"Your message information will be arriving shortly",
};

export const CONTACTS = {
  ADD_CONTACT: " Add Contact",
  SAVE: "SAVE",
  CLOSE: "CANCEL",
  IMPORT_CONTACTS: "Import Contacts",
  UPLOAD_CSV_FILE: "Click to upload",
  UPLOAD_IN_CSV: "Upload in CSV  (max. 3MB)",
  ADD_CONTACT_TO_GROUP: "ADD CONTACT TO GROUP",
  ADD_CONTACT_GROUP: "Contacts added successfully",
  GROUP_DELETE_CONTACT: "Contact deleted successfully",
  EDIT_CONTACT: "Contact Updated Sucessfully",
  GROUP_CREATE_CONTACT: "Group created successfully",
  FILE_UPLOADED_SUCCESSFULLY: "File uploaded successfully",
  ADD_CONTACTS_TO_GROUP: "Add contact to group",
  CREATE_GROUP: "Create Group",
  GROUP_ALREADY_EXISTS: "Group already exists",
  CONTACT_ALREADY_EXISTS_IN_GROUP: "Contact already exist in the group",
  Number: "+91",
  SEND: "SEND",
  CONTACTS: "contact",
  NO_EDIT_CONTACT: "No contact is updated ",
  GROUP_NAME_VALIDATION_ERROR:"Group name can only contain letters, numbers, and no spaces",
  GROUP_NAME_REGEX:"/^[a-zA-Z0-9_]*$/",
  CONTACTS_DATA:"Your contact information will be arriving shortly",
  ERROR_EXPORTING_TO_EXCEL: "Error exporting to Excel:",
};

export const Tabs = {
  HOME_TAB: "Home",
  CONTACTS_TAB: "Contacts",
  GROUPS_TAB: "Groups",
  MESSAGE_TAB: "Message",
  LOG_OFF: "Logoff",
};

export const CSV = {
  FILE_SUCCESS_UPLOAD:"Data is successfully uploaded",
  FILE_DATA_DIDNT_UPDATE:"The data failed to upload",
  FILE_UPLOAD: "File uploaded sucessfully",
  FILE_SIZE: "File size should be 3MB and below",
  FILE_REMOVED: "File Removed",
  FILE: "Click to upload",
  UPLOAD: "Upload CSV",
};

export const close = {
  CLOSE: "Close",
  CANCEL: "Cancel",
};

export const Text = {
  ALL_CONTACTS: "All Contacts",
  REACENT_MESSAGE: "React Message",
  RECENT: "Recent Messages",
  COMPOSE: "COMPOSE",
  MESSAGE_SENT: "Messages sent successfully",
};

export const REGEX = {
  NAME_REGEX: /^[a-zA-Z ]+$/,
  CONTACTS_REGEX: /^\d+$/,
};

export const UserStaticData = [
  {name: "John", countrycode:"91" ,mobile: "1203456789", address: "Erode"},
  { name: "Jane Smith", countrycode:"91" ,mobile: "1234567890", address: "Erode" },
  { name: "Nick", countrycode:"91" ,mobile: "9874561230", address: "Erode" }
];

export const title = {
  ADD_CONTACT: "Add Contact",
  EDIT_CONTACT: "Edit Contact",
  GROUP: "group",
  COMPOSE: "Compose Message",
  CONTACT: "contact",
};

export const COUNTRY_CODE = "+91";
