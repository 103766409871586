import React, { useState, useEffect, ChangeEvent, Suspense } from "react";
import Box from "@mui/material/Box";
import "../index.css";
import { COUNTRY_CODE, CSV, REGEX, Tabs, title } from "../Constant";
import axios from "axios";
import { api } from "../api";
import {
  Button,
  IconButton,
  Link,
  Stack,
  TableContainer,
  TableHead,
  styled,
  Tooltip,
  Chip,
  CircularProgress,
} from "@mui/material";
import { ERROR_MESSAGES, CONTACTS, MESSAGES } from "../Constant";
import PheonixButton from "../components/PheonixButton";
import PheonixTable from "../components/PheonixTable";
import PheonixDataTable from "../interfaces/PheonixDataTable";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteSharp } from "@mui/icons-material";
import PheonixSnackbar from "../components/PheonixSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import PheonixModal from "../components/PheonixModel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import PheonixSearch from "../components/PheonixSearch";
import PheonixCSVModal from "../components/PheonixCSV";
import PheonixDeleteModal from "../components/PheonixDeleteModal";
import PheonixFooter from "../components/PheonixFooter";
import { Contact, ContactType } from "../interfaces/PheonixContact";
import PheonixEditModal from "../components/PheonixEditModel";
import SkeletonLoader from "../components/PheonixSkeletonLoader";
import { PheonixDataGridContact } from "../components/PheonixDataGridContact";
import { saveAs } from "file-saver";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { PheonixDataGridSearch } from "../components/PheonixDataGridSearch"

const MUTATION = gql`
  mutation ($files: [Upload!]!) {
    uploadFiles(files: $files) {
      success
    }
  }
`;

const Contacts: React.FC = () => {
  const navigation = useNavigate();
  const [mutate] = useMutation(MUTATION);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [Snackbaropen, setSnackbaropen] = useState(false);
  const [filename, setFilename] = useState("");
  const [apiresponse, setApiresponse] = useState<Array<ContactType>>([]);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [nameerror, setNameerror] = useState("");
  const [phoneerror, setPhoneerror] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editContactId, setEditContactId] = useState<string>("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<ContactType[]>([]);
  const [deletemodal, setDeleteModal] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>("");
  const [filteredSno, setFilteredSno] = useState<number[]>([]);
  const [status1,setStatus1] = useState(false);
  const [loading,setLoading] = useState(false);
  const [dataLoading, setDataLoading1] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [ContactFormData, setContactFormData] = useState({
    Name: "",
    "Contact Number": "",
    Address: "",
  });
  const [ContactFormData1, setContactFormData1] = useState({
    Name: "",
    "Contact Number": "",
    Address: "",
  });
  const [isCSVmodal, setIsCSVmodal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const paperStyle = isMobile
    ? { padding: "20px", width: "auto", height: "auto" }
    : { padding: "50px", width: "444px", height: "550px" };
  const { NAME_REGEX, CONTACTS_REGEX } = REGEX;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [allContacts, setAllContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showChip, setShowChip] = useState(false);
  const [contactsFetched, setContactsFetched] = useState(false);

let responseDataWithIndex :any[];
   const handleOpenModal = (id: string) => {
    setIsEditModal(true);
    setEditContactId(id);

    if (apiresponse) {
      const contact = apiresponse.find((contact) => contact.id === id);
      if (contact) {
        const trimPhoneNumber = contact.phoneNumber.replace(/^\+91/, "");
        setContactFormData({
          Name: contact.name,
          "Contact Number": trimPhoneNumber,
          Address: contact.address,
        });
        setIsModalOpen(true);
      }
    }
  };

  const handleConfirm = async (
    id: string,
    phoneNumber: string,
    address: string,
    name: string
  ) => {
    try {

      const contactToUpdate = apiresponse.find((contact) => contact.id === id);

    if (
      contactToUpdate &&
      (contactToUpdate.name !== name ||
        contactToUpdate.phoneNumber !== COUNTRY_CODE + phoneNumber ||
        contactToUpdate.address !== address)
    ) {
      const response = await axios.post(api.baseUrl, {
        query: `
        mutation EditContactById($id: ID!, $name: String! $phoneNumber: String!,$address:String! ) {
          editContactById(id: $id, name: $name, phoneNumber: $phoneNumber,address:$address) {
            success
            message
          }
        }
      `,
        variables: {
          id: id,
          name: name,
          phoneNumber: COUNTRY_CODE + phoneNumber,
          address: address,
        },
      });

      const { success, message } = response.data.data.editContactById;

      if (success) {
        setSnackbarMessage(CONTACTS.EDIT_CONTACT);
        setSnackbaropen(true);
        setIsModalOpen(false);
        setTimeout(() => {
          setSnackbaropen(false);
        }, 3000);
        fetchData();
      } else {
        setSnackbarMessage(message);
        setSnackbaropen(true);
        setIsModalOpen(false);
        setTimeout(() => {
          setSnackbaropen(false);
        }, 3000);
      }
    }else {
      setSnackbarMessage(CONTACTS.NO_EDIT_CONTACT);
      setSnackbaropen(true);
      setIsModalOpen(false);
      setTimeout(() => {
        setSnackbaropen(false);
      }, 3000);
    }
  } 
    catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsAddModalOpen(false);
    setNameerror("");
    setPhoneerror("");
    setErrorMessage("");
    setContactFormData1({
      Name: "",
      "Contact Number": "",
      Address: "",
    });
  };

  const handleSnackbarClose = () => {
    setSnackbaropen(false);
  };

  const handleSave = async (
    name: string,
    phoneNumber: string,
    address: string
  ) => {
    const nameValid = NAME_REGEX;
    const phoneValid = CONTACTS_REGEX;

    let nameError = "";
    let phoneError = "";

    if (name.length < 2) {
      nameError = ERROR_MESSAGES.NAME_EMPTY;
    } else if (!nameValid.test(name)) {
      nameError = ERROR_MESSAGES.CONTACTS_NAME;
    }

    if (!phoneNumber) {
      phoneError = "contact is empty";
    } else if (!phoneValid.test(phoneNumber)) {
      phoneError = ERROR_MESSAGES.CONTACTS_NUMBER;
    }
    

    setNameerror(nameError);
    setPhoneerror(phoneError);

    if (nameError || phoneError) {
      return;
    }

    const query = `
      mutation AddContact($name: String!, $phoneNumber: String!, $address: String!) {
        addContact(name: $name, phoneNumber: $phoneNumber, address: $address) {
          success
          message
           contacts {
             id
             name
             phoneNumber
             address
           }
        }
      }
    `;

    const variables = {
      name: name,
      phoneNumber: COUNTRY_CODE + phoneNumber.toString(),
      address: address,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const { success, message, contacts } = response.data.data.addContact;

      if (success) {
        setSnackbarMessage(ERROR_MESSAGES.SNACKBAR_SUCCESS);
        setSnackbaropen(true);
        setIsAddModalOpen(false);
        fetchData();
        setName("");
        setPhoneNumber("");
        setAddress("");
      } else {
        setSnackbarMessage(message);
        setSnackbaropen(true);
        setPhoneerror(ERROR_MESSAGES.CONTACTS_SAMENUMBER);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePaginationChange = (newModel:any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        api.baseUrl,
        {
          query: `
        query GetContactperPage($page: Int!, $perPage: Int!) {
            getContactperPage(page: $page, perPage: $perPage) {
              totalPages
              totalCount
              contact {
                id
                name
                phoneNumber
                address
              }
            }
          }
          `,
          variables: {
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          }
        },
      );

      setApiresponse((prevResponse) => {
        const updatedResponse = response.data.data.getContactperPage.contact.map(
          (item: {}, index: number) => {
            const sno = (paginationModel.page * paginationModel.pageSize + index + 1).toString();
            return {
              ...item,
              sno: sno,
            };
          }
        );
        return updatedResponse;
      });
      
      setTotalPages(response.data.data.getContactperPage.totalCount);
      setDataLoading1(false);
    } catch (error) {
      setSnackbarMessage(ERROR_MESSAGES.ERROR);
      setSnackbaropen(true);
    }
    finally {
      setLoading(false); 
    }
  };
  
useEffect(() => {
    fetchData();
  }, [paginationModel.page,paginationModel.pageSize]); 

  const fetchContacts = async () => {
    try {
      setShowChip(true);
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
            query GetContact {
              getContact {
                name
                phoneNumber
                address 
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const contactsData = response.data.data.getContact.map((contact: { address: string; }) => ({
        ...contact,
        address: `"${contact.address.replace(/\n/g, ' ')}"`
      }));
      setAllContacts(contactsData);
      setContactsFetched(true);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setShowChip(false);
    }
  };

  const exportToExcel = () => {
    try {
      const csvData = [
        Object.keys(allContacts[0]).join(","),
        ...allContacts.map((row) => Object.values(row).join(",")),
      ].join("\n");

      const blob = new Blob([csvData], { type: "text/csv" });
      saveAs(blob, "contacts.csv");
    } catch (error) {
      console.error(CONTACTS.ERROR_EXPORTING_TO_EXCEL, error);
    }
  };

  const handleExport = async () => {
    try {
      if (!contactsFetched) {
        await fetchContacts();
      }
      exportToExcel();
    } catch (error) {
      console.error(CONTACTS.ERROR_EXPORTING_TO_EXCEL, error);
    }
  };

  const handleDeleteIconClick = (contactId: string) => {
    if (selectedContactId !== null) {
      setSelectedContactId(contactId);
      setDeleteModal(true);
    }
  };

  const handleConfirmDelete = () => {
    if (selectedContactId !== null) {
      handledelete(selectedContactId);
    }
  };

  const handledelete = async (deleteContact: string) => {
    const query = `
        mutation DeleteContact($id: String!) {
            deleteContact(id: $id) {
                success
                message
            }
        }
    `;
    const variables = {
      id: deleteContact,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const { success, message } = response.data.data.deleteContact;
      if (success) {
        setSnackbarMessage(ERROR_MESSAGES.SNACKBAR_MESSAGE);
        setSnackbaropen(true);
        setApiresponse((prevContacts) =>
          prevContacts
            .filter((contact) => contact.id !== deleteContact)
            .map((contact, index) => ({
              ...contact,
              sno: (index + 1).toString(),
            }))
        );
        setDeleteModal(false);
      } else {
        console.error("error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const column = [
    { field: "sno", headerName: "S.No", width: 100 },
    { field: "name", headerName: "Name" , width: 300},
    { field: "phoneNumber", headerName: "Contact Number" , width: 300 },
    { field: "address", headerName: "Address" , width: 300 },
    {
      field: "actions",
      headerName: "Action",
      align: "center",
      minWidth: 100,
      renderCell: (row: PheonixDataTable) => {
        return (
          <Box style={{ flexDirection: "row" }}>
             <Tooltip title="Edit">
            <IconButton  onClick={() => handleOpenModal(row?.id)} sx={{marginLeft:"-10%" }}>
              <EditIcon sx={{ color: "#2E7D32" }} />
            </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
            <IconButton  onClick={() => handleDeleteIconClick(row.id)} sx={{marginLeft:"-8%" }}>
              <DeleteSharp sx={{ color: "#D32F2F" }} />
            </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleAddContact = () => {
    setIsAddModalOpen(true);
  };

  useEffect(() => {
    fetchContacts();
  }, []);
  
  const handleCSVcontacts = () => {
    setIsCSVmodal(true);
  };

  const handleCSVfileclose = () => {
    setIsCSVmodal(false);
    setStatus1(status1);
  };

  const fetchDataSearch = async () => {
    try {
      setDataLoading1(true);
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
            query GetContact {
              getContact {
                id
                name
                phoneNumber
                address 
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseDataWithIndex = response.data.data.getContact.map(
        (item: {}, index: number) => ({
          ...item,
          sno: (index + 1).toString(),
        })
      );

      setApiresponse(responseDataWithIndex);
      setDataLoading1(false);
    } catch (error) {
      setSnackbarMessage(ERROR_MESSAGES.ERROR);
      setSnackbaropen(true);
    }
  };

  useEffect(() => {
    if (isSearching) {
      fetchDataSearch();
    }
  }, [isSearching]);

  useEffect(() => {
    if (!isSearching) {
      setPaginationModel({ ...paginationModel, page: 0 });
    }
  }, [isSearching]);

  useEffect(() => {
    const filtered = apiresponse.filter((contact) =>
      contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.address.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, apiresponse]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setIsSearching(query.trim() !== '');
    if (query.trim() === '') {
      fetchData();
    } 
  };
  

  useEffect(() => {
    if (isAddModalOpen) {
      setNameerror("");
      setPhoneerror("");
      setContactFormData1({
        Name: "",
        "Contact Number": "",
        Address: "",
      });
    }
  }, [isAddModalOpen]);

  useEffect(() => {
   if(status1){
    fetchData() 
   }
  }, [status1]);

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#F1F1F1",
        height: "100vh",
        flexDirection: "column",
        marginRight: "2%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "90%",
          height: "90px",
          marginTop: "1%",
          marginLeft: "3.5%",
          backgroundColor: "white",
          borderRadius: "20px 20px 0px 0px",
          padding: "0px 30px 0px 30px",
          boxShadow: "0px 3px 4px 0px #00000040",
        }}
      >
        <div
          style={{
            flex: 4,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <PheonixSearch onChange={handleSearch} />
        </div>
        <div
          style={{
            flex: 4,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        {dataLoading ?
        <Chip
        label={CONTACTS.CONTACTS_DATA}
        variant="outlined"
        sx={{ align:"center", right: "100%", marginTop:"7%" }}
    />
    :null
         }
         </div>
        <div
          style={{
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <PheonixButton
            variant="contained"
            onClick={handleAddContact}
            size="large"
            sx={{
              fontWeight: "bold",
              width:"150px",
              height: "50px",
              left: "40px",
              marginRight:"40px",
            }}
          >
            {MESSAGES.ADD_CONTACTS}
          </PheonixButton>
        </div>
        <div
          style={{
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <PheonixButton
          variant="contained"
          size="large"
          onClick={handleCSVcontacts}
          startIcon={<FileDownloadIcon />}
          sx={{
            fontWeight: "bold",
            height: "50px",
            right: "-5%",
            width:"max-content",
          }}
        >
          {MESSAGES.UPLOAD}
          </PheonixButton>
        </div>
        <div
          style={{
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <PheonixButton
         variant="contained"
         size="large"
         onClick={handleExport}
         startIcon={<FileUploadIcon />}
         sx={{
          fontWeight: "bold",
          height: "50px",
          right: "-10%",
          width:"max-content",
        }}
       >
         {MESSAGES.EXPORT}   
          </PheonixButton>
        </div>
      </Box>

      <Box
        sx={{
          width: "90%",
          height: "480px",
          marginTop: "1%",
          marginLeft: "3.5%",
          backgroundColor: "white",
          borderRadius: "0px 0px 20px 20px",
          padding: "0px 30px 0px 30px",
          boxShadow: "0px 3px 4px 0px #00000040",
          display: "flex",
          flexDirection: "column",
        }}
      >  
         {dataLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            {isSearching ? (
              <PheonixDataGridSearch
              columns={column}
              rows={filteredData}
              style={{ border: '0px', marginLeft: '20px'}}
              />
            ) : (
              <PheonixDataGridContact
                columns={column}
                rows={apiresponse}
                style={{ border: '0px', marginLeft: '20px'}}
                pageSizeOptions={[5, 10, 20, 30]}
                rowCount={totalPages}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </Box>
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
          fontSize: "24px",
        }}
        open={Snackbaropen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{
          width: "320px",
          height: "42px",
          "& .MuiSnackbarContent-root": {
            background: " #323232",
          },
        }}
      />

      <PheonixEditModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={title.EDIT_CONTACT}
        fields={[
          { label: "Name", type: "text" },
          { label: "Contact Number", type: "number" },
          { label: "Address", type: "text" },
        ]}
        onConfirm={() =>
          handleConfirm(
            editContactId,
            ContactFormData["Contact Number"],
            ContactFormData.Address,
            ContactFormData.Name
          )
        }
        confirmButtonText={CONTACTS.SAVE}
        formData={ContactFormData}
        isEditModal={isEditModal}
        setFormData={setContactFormData}
        close={CONTACTS.CLOSE}
        errorMessage={errorMessage}
        phonenumbererror={phoneerror}
        nameerror={nameerror}
        setErrorMessage={setErrorMessage}
        setNameerror={setNameerror}
        setPhonenoerror={setPhoneerror}
      />

      <PheonixModal
        open={isAddModalOpen}
        onClose={handleCloseModal}
        title={title.ADD_CONTACT}
        fields={[
          { label: "Name", type: "text" },
          { label: "Contact Number", type: "number" },
          { label: "Address", type: "text" },
        ]}
        onConfirm={() =>
          handleSave(
            ContactFormData1.Name,
            ContactFormData1["Contact Number"],
            ContactFormData1.Address
          )
        }
        confirmButtonText={CONTACTS.SAVE}
        formData={ContactFormData1}
        isEditModal={false}
        setFormData={setContactFormData1}
        close={CONTACTS.CLOSE}
        errorMessage={errorMessage}
        phonenumbererror={phoneerror}
        nameerror={nameerror}
        setErrorMessage={setErrorMessage}
        setNameerror={setNameerror}
        setPhonenoerror={setPhoneerror}
      />

      <PheonixCSVModal
        open={isCSVmodal}
        onClose={handleCSVfileclose}
        title={CSV.UPLOAD}
        status={status1}
        setStatus={setStatus1}
      /> 

      <PheonixDeleteModal
        open={deletemodal}
        onClose={() => setDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        entity={CONTACTS.CONTACTS}
      />
      <PheonixFooter />
    </Box>
  );
};

export default Contacts;
