import React, { useState, useEffect } from "react";
import { ERROR_MESSAGES } from "../Constant";
import PheonixFooterProps from "../interfaces/PheonixFooterProps";

const PheonixFooter: React.FC<PheonixFooterProps> = ({ style }) => {
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div
      style={{
        textAlign: "center",
        fontFamily: "Inter",
        color: " #1A504C",
        fontSize: "10px",
        fontWeight: 600,
        marginTop: "1px",
        position: "fixed",
        bottom: 5,
        left: 130,
        right: 0,
      }}
    >
      &copy;{currentYear}
      <span> {ERROR_MESSAGES.FOOTER_TXT}</span>{" "}
      <a
        href="https://www.pheonixsolutions.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#2196F3",
          fontFamily: "Inter",
          fontWeight: 600,
          textDecoration: "none",
          fontSize: "10px",
        }}
      >
        {ERROR_MESSAGES.PHEONIX_TXT}
      </a>
    </div>
  );
};
export default PheonixFooter;
