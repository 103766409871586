import React, { useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import PheonixSearchProps from "../interfaces/PheonixSearchProps";

const PheonixSearch: React.FC<PheonixSearchProps> = ({ onChange }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    onChange(query);
  };
  return (
    <div
      style={{
        display: "flex",
        width: "220px",
        marginLeft: "25px",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "2px",
      }}
    >
      <TextField
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={handleChange}
        placeholder="Name"
        fullWidth
        margin="normal"
        sx={{
          "& label.Mui-focused": {
            color: "#59356C",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#59356C",
          },
          "& .MuiInputLabel-root": {
            color: "#0000003B",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#59356C!important",
            },
          },
        }}
      />
    </div>
  );
};

export default PheonixSearch;
